<template>
  <div class="auth-wrapper auth-v2">
    <v-overlay :value="overlay">
      <v-card color="primary" style="width: 400px">
        <v-card-text class="pt-3 white--text">
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingDialogText }} {{ loadingIndeterminate ? '' : `(${Math.floor(percentageLoadingAll)}%)` }}
          <v-progress-linear
            v-model="percentageLoadingAll"
            height="5"
            color="white"
            :indeterminate="loadingIndeterminate"
          >
          </v-progress-linear>
        </v-card-text>
        <v-card-text>
          <v-btn
            v-if="showSkip"
            rounded
            small
            right
            light
            class="offset-lg-8 offset-md-8 offset-sm-8 offset-xs-6 offset-xl-8 offset-8"
            @click="skipDownload"
          >
            Skip
            <v-icon small right>
              {{ icons.mdiSkipForward }}
            </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>
        <v-divider />

        <v-card-text> Username / password is wrong </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col cols="12" lg="8" class="d-lg-block position-relative overflow-hidden pa-0 align-self-end">
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pe-0">
              <!-- src="https://assets1.lottiefiles.com/packages/lf20_q5pk6p1k.json" -->
              <!-- src="https://assets8.lottiefiles.com/packages/lf20_162jfba4.json" -->
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_p33c5wk5.json"
                background="transparent"
                speed="1"
                class="lottie-image mx-auto"
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to {{ appName }}!</p>
                  <p class="mb-2">Please sign-in to your account and start the adventure</p>
                </v-card-text>

                <!-- login form -->
                <v-text-field
                  v-model="userLogin.email"
                  required
                  label="Email"
                  :prepend-inner-icon="icons.mdiCounter"
                  :rules="[validators.required]"
                  outlined
                  dense
                  hide-details
                  class="m-8"
                  autocomplete="new-password"
                  type="email"
                  placeholder="XXXX.@gmail.com"
                ></v-text-field>
                <v-text-field
                  v-model="userLogin.password"
                  outlined
                  dense
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="Password"
                  autocomplete="new-password"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="m-8"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
                <div class="d-flex align-center justify-space-between flex-wrap m-10">
                  <v-checkbox hide-details label="Remember Me" class="mt-0"> </v-checkbox>

                  <!-- forget link -->
                  <router-link :to="{ name: 'auth-forgot-password' }" class="ms-3"> Forgot Password? </router-link>
                </div>
                <v-card-text>
                  <v-btn block color="primary" class="mt-6" width="80%" @click="saveLoginToDb()"> Login </v-btn>
                </v-card-text>
                <v-card-text>
                  <!-- <div
                    id="g_id_onload"
                    data-client_id="204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com"
                    data-context="signin"
                    data-ux_mode="popup"
                    data-callback="bypassLogin()"
                    data-itp_support="true"
                  ></div>

                  <div
                    class="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="signin_with"
                    data-size="large"
                    data-logo_alignment="center"
                    data-width="250"
                  ></div> -->
                  <div id="buttonDiv"></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- <v-dialog v-model="loginDialog" width="80%">
      <v-card>
        <v-card-title> Login </v-card-title>
        <v-text-field
          v-model="userLogin.email"
          required
          label="Email"
          :prepend-inner-icon="icons.mdiCounter"
          outlined
          hide-details
          class="m-8"
          type="email"
          placeholder="XXXX.@gmail.com"
        ></v-text-field>
        <v-text-field
          v-model="userLogin.password"
          required
          label="Password"
          :prepend-inner-icon="icons.mdiCounter"
          outlined
          hide-details
          class="m-8"
          type="password"
          placeholder=".................."
        ></v-text-field>
        <v-card-text>
          <v-btn block color="primary" class="mt-6" width="80%" @click="bypassLogin"> Login </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
/* eslint-disable */

// import built in
// icon
import { mdiEyeOffOutline, mdiEyeOutline, mdiLock, mdiSkipForward, mdiWhatsapp } from '@mdi/js'
import themeConfig from '@themeConfig'
import { OAuth2Client } from 'google-auth-library'
import PouchDB from 'pouchdb-browser'
// import custom
import Vue from 'vue'
import GSignInButton from 'vue-google-signin-button'
import lokijsMixin from '../mixins/lokijsMixin'
// import custom
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { emailValidator, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import pouchDbMixin from '../mixins/pouchDbMixin'
Vue.use(GSignInButton)
PouchDB.adapter('worker', require('worker-pouch'))

export default {
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      validators: {
        required,
        emailValidator,
      },
    }
  },
  name: 'Login',
  mixins: [pouchDbMixin, lokijsMixin],
  computed: {
    userState() {
      return this.$store.getters['auth/getUserState']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    percentageLoadingAll() {
      const load = (this.percentageLoading + this.percentageLoadingChats) / 2
      return load > 100 ? 100 : load
    },
  },
  data() {
    return {
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      },
      loginLink: '',
      loginData: '',
      loginLoaded: '',
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      overlay: false,
      valueProgress: 0,
      icons: {
        mdiWhatsapp,
        mdiLock,
        mdiSkipForward,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // Loading
      loadingDialog: false,
      loadingDialogText: '',
      loginDialog: false,
      loadingIndeterminate: true,
      percentageLoading: 0,
      percentageLoadingChats: 0,
      isFinish: false,
      lastDiffCount: 0,
      retryCount: 0,
      lastDiffCountChats: 0,
      retryCountChats: 0,
      loadingInterval: null,
      loadingIntervalChats: null,
      showSkip: false,

      boldUnreplied: false,
      autoAssignChatOnReply: true,
      autoAssignNewChat: true,
      notifAllOrAssigned: { name: this.$t('notifFromChannel'), data: 2 },
      labelValue: [],
      instances: {
        value: [],
      },
      peerConnection: null,
      userLogin: {
        email: '',
        password: '',
      },
      dialogAlert: false,
    }
  },

  async mounted() {
    this.googleFunction()
    if (this.userState !== null) {
      this.overlay = true
      const userState = JSON.parse(JSON.stringify(this.userState))
      await this.saveLoginToDb(userState)
    }
  },
  methods: {
    googleFunction() {
      const callback = this.saveLoginToDb
      {
        google.accounts.id.initialize({
          client_id: '204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com',
          callback,
        })
        google.accounts.id.renderButton(
          document.getElementById('buttonDiv'),
          { theme: 'outline', size: 'large', width: '370' }, // customization attributes
        )
        google.accounts.id.prompt() // also display the One Tap dialog
      }
    },
    async clickLogin() {
      const { name, state, codeVerifier, codeChallenge, codeChallengeMethod, authUrl } = (
        await clientPocketBase.users.listAuthMethods()
      ).authProviders[0]
      const currentLocation = process.env.VUE_APP_URL
      this.$store.commit('global/setLoginState', {
        name,
        state,
        codeVerifier,
        codeChallenge,
        codeChallengeMethod,
        authUrl,
      })
      this.loginLink = currentLocation
      // await Browser.open({ url: authUrl + currentLocation + '/login/redirect' })
      // Browser.addListener('browserFinished', data => {
      //   //console.log(JSON.stringify(data), 'browser exited')
      //   this.loginData = JSON.stringify(data)
      // })
      // Browser.addListener('browserPageLoaded', data => {
      //   //console.log('🚀 ~ file: Login.vue ~ line 262 ~ clickLogin ~ data2', data)
      //   this.loginLoaded = JSON.stringify(data)
      // })
      window.location.href = authUrl + currentLocation + '/login/redirect'
      // this.overlay = true
    },
    async openLoginDialog() {
      this.loginDialog = true
    },
    // async bypassLogin() {
    //   await clientPocketBase.admins.authWithPassword(process.env.VUE_APP_PB_USER, process.env.VUE_APP_PB_PASS)
    //   let userData
    //   try {
    //     console.log(`email = '${this.userLogin.email}' && isActive = True`)
    //     userData = await clientPocketBase
    //       .collection('users')
    //       .getFirstListItem(`email = '${this.userLogin.email}' && isActive = True`, {
    //         expand: 'crmRole',
    //         $autoCancel: false,
    //       })

    //     // if (userData.email !== this.userLogin.password) {
    //     //   throw `not match password`
    //     // }
    //   } catch (err) {
    //     console.log('🚀 nyund ~ file: Login.vue:366 ~ bypassLogin ~ err:', err)
    //     this.userLogin.email = ''
    //     this.userLogin.password = ''
    //     this.dialogAlert = true
    //     return
    //   }
    //   this.loginDialog = false
    //   userData.skip_oauth2 = true
    //   userData.chat_mode = userData.expand?.crmRole?.chat_mode
    //   userData.role = userData.expand?.crmRole?.role || 'Admin'
    //   userData.limited_menu = userData.expand?.crmRole?.limited_menu
    //   userData.limited_channel = userData.expand?.crmRole?.limited_channel
    //   userData.enabled_menu = userData.expand?.crmRole?.enabled_menu
    //   userData.enabled_channel = userData.expand?.crmRole?.enabled_channel
    //   userData.package = 'business_plus'
    //   await this.saveLoginToDb(userData, false)
    // },
    // async bypassLoginGoogle(googleResponse) {
    //   if (googleResponse) {
    //     // let adminUser = await clientPocketBase.admins.authWithPassword(
    //     //   process.env.VUE_APP_PB_USER,
    //     //   process.env.VUE_APP_PB_PASS,
    //     // )
    //     const token = googleResponse.credential
    //     const client = new OAuth2Client('204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com')
    //     const ticket = await client.verifyIdToken({
    //       idToken: token,
    //       audience: '204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com',
    //     })
    //     const payload = ticket.getPayload()
    //     let userData
    //     let idRole
    //     try {
    //       idRole = await clientPocketBase.collection('crm_role').getFullList(
    //         400,
    //         {
    //           filter: `sub_id = 'All' && role = 'Admin'`,
    //         },
    //         {
    //           $autoCancel: false,
    //         },
    //       )
    //     } catch (error) {
    //       console.log(error, 'error get user')
    //     }

    //     try {
    //       userData = await clientPocketBase.collection('users').getFirstListItem(`email = '${payload.email}'`, {
    //         expand: 'crmRole',
    //       })
    //     } catch (err) {
    //       if (err.status === 404) {
    //         try {
    //           await clientPocketBase.collection('users').create(
    //             {
    //               email: payload.email,
    //               password: payload.email,
    //               passwordConfirm: payload.email,
    //               emailVisibility: true,
    //               sub_id: 's' + this.$nanoSub(),
    //               name: payload.name,
    //               crmRole: idRole[0].id,
    //             },
    //             { $autoCancel: false },
    //           )
    //         } catch (error) {
    //           console.log(error, 'error create new user')
    //         }

    //         try {
    //           userData = await clientPocketBase.collection('users').getFirstListItem(`email = '${payload.email}'`, {
    //             expand: 'crmRole',
    //             $autoCancel: false,
    //           })
    //         } catch (error) {
    //           console.log(error, 'error get userData')
    //         }
    //       }
    //     }
    //     userData.token =
    //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzMXFnNGpjYmMiLCJpYXQiOjE2NTkwOTI3Njl9.yc0lVbYB3NmW0grVRBWDwCxG-2lnNj8IQGHSMNijKG4'
    //     userData.skip_oauth2 = true
    //     userData.chat_mode = userData.expand?.crmRole?.chat_mode
    //     userData.role = userData.expand?.crmRole?.role || 'Admin'
    //     userData.limited_menu = userData.expand?.crmRole?.limited_menu
    //     userData.limited_channel = userData.expand?.crmRole?.limited_channel
    //     userData.enabled_menu = userData.expand?.crmRole?.enabled_menu
    //     userData.enabled_channel = userData.expand?.crmRole?.enabled_channel
    //     userData.package = 'business_plus'
    //     await this.saveLoginToDb(userData, true)
    //     this.loginDialog = false
    //   }
    // },
    skipDownload() {
      this.percentageLoading = 100
      this.percentageLoadingChats = 100
      this.isFinish = true
    },
    async onSignInSuccess(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users

      var obj = googleUser.getBasicProfile()
      var token = googleUser.getAuthResponse(true)
      var user = {
        email: obj.getEmail(),
        name: obj.getName(),
        image: obj.getImageUrl(),
        token: token.id_token,
      }
      // //console.log('🚀 ~ file: Login.vue ~ line 285 ~ onSignInSuccess ~ user', user)

      await this.saveLoginToDb(user)
    },
    onSignInError(error) {
      this.overlay = false
      //console.log('Sign in google failed', error)
      this.$dialog.message.error('Sign in google failed', { position: 'top-right' })
    },
    async saveLoginToDb(googleResponse) {
      // console.log('🚀 nyund ~ file: Login.vue:489 ~ saveLoginToDb ~ googleResponse:', googleResponse)
      this.overlay = false
      this.loadingDialog = true
      this.loadingDialogText = 'Prepare Data'

      // CONNECT TO DB LOCAL
      this.loadingDialog = true
      this.loadingDialogText = 'Load Data...'
      await this.connectSettings(false)
      await this.connectContacts(false)
      await this.connectHistory(false)
      await this.connectLink(false)

      // REMOVE VUEX STORE
      await this.$store.dispatch('global/setContacts', [])
      await this.$store.dispatch('global/setSettings', [])
      await this.$store.dispatch('global/setLink', [])
      await this.$store.dispatch('global/setRooms', null)
      await this.$store.dispatch('global/setFilterChat', null)
      await this.$store.dispatch('global/setLastSeq', 0)
      await this.$store.dispatch('global/setStatusChannels', false)
      await this.$store.commit('auth/setListInstance', [])
      await this.$store.dispatch('global/updateReplicateStatus', {
        contacts: 'idle',
        settings: 'idle',
        broadcasts: 'idle',
        history: 'idle',
        chat: 'idle',
        link: 'idle',
      })
      await this.$store.commit('global/resetMessageObj', {
        message: 'cegah',
      })
      let payload
      if (googleResponse) {
        const token = googleResponse.credential
        const client = new OAuth2Client('204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com')
        const ticket = await client.verifyIdToken({
          idToken: token,
          audience: '204038713541-n3t9ofjpkqevjk7iovbf645pbh4n17gj.apps.googleusercontent.com',
        })
        payload = ticket.getPayload()
      }

      let adminUser
      try {
        try {
          const { email } = this.userLogin
          const {
            data: { data: auth },
          } = await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}auth/login`, {
            email: googleResponse ? payload.email : email,
            fromGoogle: googleResponse ? true : false,
            name: googleResponse ? payload.name : null,
          })
          adminUser = auth
        } catch (err) {
          console.log(err.message, 'err<<')
          this.loadingDialog = false
          this.loadingDialogText = ''
          this.dialogAlert = true
          return
        }
        if (adminUser) {
          let userData = adminUser
          // try {
          //   userData = await clientPocketBase
          //     .collection('users')
          //     .getFirstListItem(`email = '${this.userLogin.email}' && isActive = True`, {
          //       expand: 'crmRole',
          //       $autoCancel: false,
          //     })

          //   // if (userData.email !== this.userLogin.password) {
          //   //   throw `not match password`
          //   // }
          // } catch (err) {
          //   console.error('error get user data', err)
          //   this.loadingDialog = false
          //   this.loadingDialogText = ''
          //   this.dialogAlert = true

          //   return
          // }
          userData.skip_oauth2 = true
          userData.chat_mode = userData.expand?.crmRole?.chat_mode
          userData.role = userData.expand?.crmRole?.role || 'Admin'
          userData.limited_menu = userData.expand?.crmRole?.limited_menu
          userData.limited_channel = userData.expand?.crmRole?.limited_channel
          userData.enabled_menu = userData.expand?.crmRole?.enabled_menu
          userData.enabled_channel = userData.expand?.crmRole?.enabled_channel
          userData.package = 'business_plus'
          localStorage.setItem('lastLogonUser', userData.email)
          this.$store.commit('auth/setUser', userData)

          // REMOVE LOKIJS
          const lastLogonUser = localStorage.getItem('lastLogonUser')
          if (lastLogonUser) {
            if (lastLogonUser != userData.email) {
              this.removeContactsLoki()
              this.removeChatsLoki()
            }
          }

          // let users
          // try {
          //   users = await clientPocketBase
          //     .collection('users')
          //     .getFirstListItem(`email = '${data.email}'`, { $autoCancel: false })
          // } catch (err) {
          //   if (err.status === 404) {
          //     try {
          //       await clientPocketBase.collection('users').create({
          //         email: data.email,
          //         name: data.name,
          //         password: data.email,
          //         passwordConfirm: data.email,
          //         sub_id: this.user.sub_id,
          //       })
          //       users = await clientPocketBase.collection('users').getFirstListItem(`email = '${data.email}'`)
          //     } catch (error) {
          //       console.log(error.data, 'ini err2')
          //     }
          //   }
          // }

          try {
            await clientPocketBase
              .collection('users')
              .authWithPassword(
                this.userLogin.email || userData.email,
                googleResponse ? userData.email : this.userLogin.password,
              )
            // let userDas = await clientPocketBase
            //   .collection('users')
            //   .getFirstListItem(`email = '${this.userLogin.email}'`, { $autoCancel: false })
            // const userData = {}
            // // .getFirstListItem(`email = '${this.userLogin.email}'`, { $autoCancel: false })
          } catch (error) {
            console.log(error, 'woi<')
            clientPocketBase.authStore.clear()
            localStorage.removeItem('pocketbase_auth')
            this.loadingDialog = false
            this.loadingDialogText = ''
            this.dialogAlert = true
            return
          }
        }
      } catch (err) {
        console.log('🚀 ~ file: Login.vue ~ line 543 ~ saveLoginToDb ~ err', err)
      }
      this.$store.commit('auth/setLogin', true)

      // try {
      //   await this.$axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}auth/login`, {
      //     subId: data.sub_id,
      //   })
      // } catch (error) {}

      const listInstances = await clientPocketBase.collection('crm_instances').getFullList(50)

      if (listInstances && listInstances.length) {
        this.instances.value = [...listInstances].filter(instance => {
          if (this.user.limited_channel) {
            return (
              this.user.enabled_channel &&
              this.user.enabled_channel.find(channel => channel === instance._id || channel === instance.phone_number)
            )
          }
          return true
        })

        this.$store.commit('auth/setListInstance', this.instances)
      } else {
        try {
          if (adminUser.token) {
            const { data: user } = await this.$axiosLimit.get(
              `${process.env.VUE_APP_POCKETBASE_URL}/api/collections/users/records?filter=(email = '${this.user.email}')`,
              {
                headers: {
                  Authorization: `${adminUser.token}`,
                },
              },
            )

            if (user.totalItems > 0) {
              const { profile } = user.items[0]
            }
            if (user.totalItems > 0) {
              const { profile } = user.items[0]
              await this.$axiosLimit.patch(
                `${process.env.VUE_APP_POCKETBASE_URL}/api/collections/profiles/records/${profile.id}`,
                {
                  active_subId: this.user.sub_id,
                },
                {
                  headers: {
                    Authorization: `${adminUser.token}`,
                  },
                },
              )
            }
          }
        } catch (err) {
          console.log(err, 'auth pocketbase error')
        }
      }

      const instanceIds = this.instances.value.map(el => this.$Md5Hash(el._id))
      await this.$store.dispatch('global/findAllContactData', {
        sub_id: this.user.sub_id,
        instances: instanceIds,
      })

      this.$store.commit('inbox/setCurrentOffset', 0)
      this.$store.commit('auth/setUserState', null)
      this.percentageLoading = 100
      this.isFinish = true
      this.overlay = false
    },
    async checkLoading() {
      const checkReplicate = await this.checkReplicateContacts()
      this.percentageLoading = checkReplicate.percent
      if (this.lastDiffCount === checkReplicate.diff) {
        this.retryCount += 1
      } else {
        this.retryCount = 0
      }
      this.lastDiffCount = checkReplicate.diff

      if (this.retryCount > 4) {
        const conn = await this.checkStatusConnectionServer()
        if (conn) {
          this.retryCount = 0
          this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user)
        } else {
          // this.loadingDialog = false
          this.lastDiffCount = 0
          this.retryCount = 0
          if (this.loadingInterval) clearInterval(this.loadingInterval)
        }
      }

      this.finishing()
    },
    async checkLoadingChats() {
      const checkReplicate = await this.checkReplicateChats()
      this.percentageLoading = checkReplicate.percent
      //console.log(checkReplicate.diff, checkReplicate.percent, 'checkReplicate.percent')
      if (this.lastDiffCountChats === checkReplicate.diff) {
        this.retryCountChats += 1
      } else {
        this.retryCountChats = 0
      }
      this.lastDiffCountChats = checkReplicate.diff

      if (this.retryCountChats > 4) {
        const conn = await this.checkStatusConnectionServer()
        if (conn) {
          this.retryCountChats = 0
          this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CHAT_NAME, this.user)
        } else {
          // this.loadingDialog = false
          this.lastDiffCountChats = 0
          this.retryCountChats = 0
          if (this.loadingIntervalChats) clearInterval(this.loadingIntervalChats)
        }

        this.finishing()
      }
    },
    finishing() {
      if (this.percentageLoadingChats > 99 && this.percentageLoading > 99) {
        this.loadingRooms = true
        this.loadingDialogText = 'Finishing'
        this.loadingIndeterminate = true
        if (this.loadingIntervalChats) clearInterval(this.loadingIntervalChats)
        if (this.loadingInterval) clearInterval(this.loadingInterval)
        this.lastDiffCount = 0
        this.retryCount = 0
        this.lastDiffCountChats = 0
        this.retryCountChats = 0
      }
    },
  },
  watch: {
    percentageLoading() {
      if (this.percentageLoading > 98 || this.isFinish) {
        this.$router.push({ name: 'inbox' }, () => {})
      }
    },
    isFinish() {
      if (this.percentageLoading > 98 || this.isFinish) {
        this.$router.push({ name: 'inbox' }, () => {})
      }
    },
  },
  beforeDestroy() {
    this.loadingDialog = false
    if (this.loadingInterval) clearInterval(this.loadingInterval)
    if (this.loadingIntervalChats) clearInterval(this.loadingIntervalChats)
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.lottie-image {
  width: 65%;
}
</style>
